import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { About, Contact, DeleteUser, Home, PrivacyPolicy, TermsCondtions } from "../Pages";
import Layout from "../Pages/Layout/Layout";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/terms-condtions" element={<TermsCondtions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>

        <Route path="/delete-request" element={<DeleteUser />} />
        <Route path="/delete-account/:token" element={<DeleteUser />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
