import React from "react";
import "./Delete.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { Form, Input, notification } from "antd";
import { deleteAccount, deleteRequest } from "../../Services/Api/Api";

const DeleteUser = () => {
  const [form] = Form.useForm();
  const { token } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isReqUrl = pathname?.includes("request");

  const onFinish = async (values) => {
    const trimmedEmail = values.email.trim();
    let result;
    if (isReqUrl) {
      result = await deleteRequest({ email: trimmedEmail });
    } else {
      result = await deleteAccount({ email: trimmedEmail, token });
    }
    const { data, error, status } = result || {};

    if (status === 200) {
      notification.success({ message: data?.message });
      form.resetFields();

      if (!isReqUrl) {
        window.history.replaceState(null, null, "/");
        navigate("/");
      }
    } else {
      notification.error({ message: error?.message || "Something went wrong" });
    }
  };

  return (
    <div className="auth-layout">
      <div className="auth-container">
        <div className="left">
          <Link to="/" className="logo">
            <img src="/assets/logo.png" alt="image" />
          </Link>
        </div>
        <div className="right">
          <div className="auth-form-wrapper">
            <h2 className="title"> Delete Your Account</h2>
            <h4 className="sub-title">
              Enter the email address associated with your account below.
            </h4>

            <Form form={form} className="forgot-form" onFinish={onFinish}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Invalid Email",
                  },
                  {
                    required: true,
                    message: "You cannot leave this field blank",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email Address"
                  size="large"
                />
              </Form.Item>

              {isReqUrl ? (
                <div className="button-group">
                  <Button
                    type="submit"
                    variant="contained"
                    className="main-button"
                  >
                    Send
                  </Button>
                  <Link to="/" className="cancel">
                    <Button variant="outlined"> Cancel </Button>
                  </Link>
                </div>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  className="main-button"
                >
                  Delete Account
                </Button>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteUser;
